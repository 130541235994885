export default {
    "Total pledge": "Tổng cam kết",
    "Current pledge": "Cam kết hiện tại",
    Reward: "Phần thưởng",
    "Total Reward": "Tổng phần thưởng",
    "Invite Friends": "Mời bạn bè",
    Invite: "Lời mời",
    Personal: "Cá nhân",
    Introduce: "Giới thiệu",
    Detail: "Chi tiết",
    Notice: "Thông báo",
    "Platform pledge amount": "Số tiền cam kết nền tảng",
    "Clown Fish": "Cá hề" ,
    Harvest: "Thu hoạch",
    "New user only": "Chỉ người dùng mới",
    Pledge: "Cầm cố",
    Fishing: "Cầm cố",
    "Day earn rate": "Tỷ lệ hoàn vốn hàng ngày",
    Max: "Tối đa",
    "Referral link": "Liên kết lời mời",
    Copy: "Sao chép",
    "Contact us": "Liên hệ với chúng tôi",
    "My invitation code": "Mã lời mời của tôi",
    "Using Decentralized Finance(DEFI), user funds are aggregated through smart contracts, and the unique FFT fund protection mechanism can effectively avoid the selection of income for projects with the highest mining yield using unique technology. Poor projects, the original pull-out machine gun pool play method, according to historical backtesting, can achieve an annual income of 482%. We open source the project for everyone to use (the core code is kept secret), and pass the review of a professional third-party code review organization to ensure the safety of all user funds.":
    "Sử dụng Tài chính phi tập trung (DEFI), tiền của người dùng được tổng hợp thông qua hợp đồng thông minh và cơ chế bảo vệ quỹ FFT duy nhất có thể tránh thu nhập từ việc chọn dự án có sản lượng khai thác cao nhất bằng công nghệ độc đáo một cách hiệu quả. Các dự án kém, phương pháp ban đầu là rút súng máy, theo lịch sử kiểm tra lại, thu nhập hàng năm có thể đạt tới 482%. Chúng tôi mở nguồn dự án cho mọi người sử dụng (mã lõi được giữ bí mật) và vượt qua đánh giá của một tổ chức đánh giá mã bên thứ ba chuyên nghiệp để đảm bảo rằng tất cả người dùng tài trợ cho Bảo mật.",
    "Enter amount": "Nhập số tiền",
    Submit: "nộp",
    "Clown fish  is only for new users, you can invite your friends to join to receive benefits.You will have a total of 1 pledge opportunity within 24 hours, invite friends to unlock more pledge amount":
    "Cá hề chỉ dành cho người dùng mới , bạn có thể mời bạn bè của mình tham gia để nhận lợi ích. Bạn sẽ có tổng cộng 1 cơ hội cam kết trong vòng 24 giờ, hãy mời bạn bè để mở khóa thêm số tiền cam kết",
    Connect: "Kết nối",
    Address: "Địa chỉ", 
    Level: "Cấp độ" ,
    Time: "Thời gian",
    "Fish Pond Type": "Loại ao cá",
    Earn: "Kiếm tiền",
    "Withdrawal Amount": "Số tiền rút",
    Team: "Đội",
    Amount: "Số tiền",
    Rate: "Lãi suất",
    "Please enter the amount!": "Vui lòng nhập số tiền!",
    "Pledge amount": "Số tiền cam kết:",
    "Wallet calling...": "Gọi ví...",
    "Operation failed!": "Thao tác thất bại!",
    "Operation successful": "Thao tác thành công!",
    "Best DEFI vault": "Kho tiền DEFI mạnh nhất",
    Copied: "Đã sao chép!",
    "Cannot copy!": "Không thể sao chép!",
    "Click to view": "Nhấp để xem",
    Prompt: "Lời nhắc",
    "Return Notice": "Số tiền rút không thành công sẽ là trong vòng 24 Vui lòng đảm bảo có đủ phí xăng",
    "Referee Reward": "Thu nhập khuyến mãi",
    "Withdrawal Income": "Rút tiền",
    "Earn Type": "Loại kiếm tiền",
    Status: "Trạng thái",
    "User Address": "Người dùng hiện tại",
    "Subordinate Address": "Người dùng cấp dưới",
    "First Generation": "Thế hệ thứ nhất",
    "Second Generation": "Thế hệ thứ hai",
    "Third Generation": "Thế hệ thứ ba",
    "Not extracted": "Chưa giải nén",
    Extracting: "Đang giải nén",
    "Extraction Complete": "Trích xuất hoàn tất",
    "Extraction Failed": "Trích xuất thất bại",
    "Draw Time": "Thời gian trích xuất",
    "Draw Hash": "Trích xuất HASH",
    OK: "OK",
    Cancel: "Hủy",
    Approve: "Ủy quyền",
    "Available direct referral": "Phần thưởng giới thiệu trực tiếp khả dụng",
    "Draw direct referral rewards": "Đã nhận được phần thưởng.",
    "Available team rewards": "Phần thưởng đội có sẵn",
    "Draw team rewards": "Đã nhận phần thưởng đội",
    "Promotion income": "Thu nhập đề xuất",
    "Team income": "Thu nhập nhóm",
    "User level": "Cấp độ người dùng",
    "Team pledge amount": "Số tiền cam kết của nhóm" ,
    "Switch Chain": "Chuỗi chuyển đổi",
    "Switch Language": "Chuyển đổi ngôn ngữ",
    Home: "Nhà",
    "Machine Gun Pool": "Nhóm súng máy",
    My: "Của tôi",
    "No data": "Không có dữ liệu",
    "The total pledge of the platform": "Tổng cam kết của nền tảng",
    "Total address": "Tổng địa chỉ",
    "Minimum profit ratio": "Tỷ lệ lợi nhuận tối thiểu",
    "FD related introduction": "Giới thiệu liên quan đến FD",
    "Scan the whole network": "Quét toàn bộ mạng",
    "Real-time data analysis to execute the best strategy":
    "Giám sát thời gian thực của phân tích dữ liệu trên chuỗi, tự động thực hiện các chiến lược chênh lệch giá định lượng khác nhau như bánh sandwich, khoản vay chớp nhoáng, dex-cex, v.v.",
    "Strategy mix": "Kết hợp chiến lược",
    "Automatic switching of policies to ensure high yield":
    "Quỹ Fuding ngoại trừ Trên thị trường giao dịch tiền kỹ thuật số, thông qua việc kết hợp các chiến lược đầu tư ngắn hạn, trung hạn và dài hạn với đòn bẩy cao, hệ thống giao dịch tự động có thể tạo ra lợi nhuận cao, ngoài ra, hợp tác với nhiều đối tác để thực hiện mã thông báo mô hình đầu tư kinh tế thông qua tài nguyên chuỗi khối của riêng họ. , nhận phản hồi đầu tư hiệu quả cao thông qua việc phát hành tiền kỹ thuật số và hoạt động đầu tư tiền tệ kép.",
    "Financial freedom": "Tự do tài chính",
    "Deposit and withdraw anytime, flexible funds": "Gửi và rút tiền bất cứ lúc nào, tiền linh hoạt",
    "Latest transaction": "Giao dịch mới nhất",
    "To view": "Xem",
    "Security audit": "Kiểm tra bảo mật",
    Partner: "Đối tác",
    "Contact Customer Service": "Liên hệ với dịch vụ khách hàng",
    "The strongest DEFI machine gun pool": "Định lượng, quản lý tài chính, Defi",
    "FD owns the world's top ten funds' far-sighted financial plan and invites you to share financial returns!":
      "FD có kế hoạch tài chính hướng tới tương lai của mười quỹ hàng đầu thế giới và mời bạn chia sẻ lợi nhuận tài chính của mình!",
    "Beginner's Guide":"Hướng dẫn Newbie",
    Welcome: "Chào mừng",
    "FD enjoy high yield": "FD có năng suất cao",
    Overview: "Tổng quan",
    "Pledge Record": "Bản ghi cam kết",
    "Current Reward": "Phần thưởng hiện tại",
    "Please link the wallet first": "Vui lòng liên kết ví trước",
    "Invitation link": "Liên kết lời mời",
    "Total number of invites": "Tổng số lời mời",
    "Invitation total revenue": "Tổng doanh thu từ lời mời",
    "Team Rewards": "Phần thưởng đội",
    "Harvest Reward": "Phần thưởng thu hoạch",
    "Level description": "Mô tả cấp độ",
    Introduction: "Giới thiệu",
    "Copy the link and share it with your friends, you will get higher pledge registration and enjoy higher investment income!":
      "Sao chép liên kết và chia sẻ nó với bạn bè của bạn, bạn sẽ nhận được đăng ký cam kết cao hơn và hưởng thu nhập đầu tư cao hơn!",
    "Company Profile": "Giới thiệu công ty",
    "We are a business entity of Fortune Group...":
    "Chúng tôi là nhà điều hành chiến lược quỹ Fidelity nổi tiếng thế giới, một công ty quỹ mở rộng đầu tư vào tài sản kỹ thuật số. Trong những năm gần đây, với sự phổ biến trên toàn thế giới của tiền kỹ thuật số, nó đã được chấp nhận và sử dụng rộng rãi cho các giao dịch thể thao và hàng hóa ảo khác nhau trên Internet. Dựa vào lợi thế chuyên môn của mình trong các sản phẩm tài chính và khu vực Hoài Hoài nước ngoài, tập đoàn đã thành lập Công ty Fuding. Tạo ra lợi nhuận hàng năm hiệu quả, ổn định và cao thông qua các hoạt động chiến lược đầu tư có hệ thống và cực kỳ hiệu quả. Thông qua gây quỹ cộng đồng đầu tư toàn cầu, cơ sở giao dịch có thể được mở rộng và trong trường hợp quỹ tăng trưởng ổn định, nó có thể mang lại tình huống đôi bên cùng có lợi cho công chúng đầu tư và công ty. ",
    "We have the world's top 30 investment strategy teams...":
    "Chúng tôi có 30 nhóm chiến lược đầu tư hàng đầu thế giới, được hưởng lợi tức đầu tư cao trong hợp đồng tương lai hàng hóa, hợp đồng tương lai chỉ số và ngoại hối. Đặc biệt là trong chiến lược đầu tư tài sản kỹ thuật số trong hai năm qua, thông qua các kế hoạch chiến lược ngắn hạn, trung hạn và dài hạn, tỷ lệ trung bình hàng năm đã đạt hơn 300%. ",
    "FishDefi has a total of three chains: ETHTRXBSC...":
    "FishDefi có tổng cộng ba chuỗi: ETHTRXBSC để bạn lựa chọn; cam kết 10U là chỉ khả dụng cho người dùng mới và số dư trong ví phải có 110U để đặt cược 10U: Ngoài ra, hãy đảm bảo bạn có đủ phí kênh (TRX) cho mỗi mũi tên trong ví của mình. Bạn sẽ có cơ hội kích hoạt tài khoản của mình trong vòng 24 giờ. Sau 24 giờ, hợp đồng sẽ tự động trả lại số tiền cam kết và lợi nhuận vào tài khoản ví của bạn. Bạn có thể mời bạn bè của mình tham gia để đạt cấp độ cao hơn và mở khóa nhiều tiền cam kết hơn",
    "After the first pledge is over...":
      "Sau khi cam kết đầu tiên kết thúc: bạn sẽ trở thành khách hàng VIP của chúng tôi: khách hàng VIP có thể tận hưởng 7 * 24 giờ rút tiền gốc+lợi nhuận: thời gian đến phụ thuộc vào tình huống chặn phòng thủ: khách hàng VIP cần chia sẻ liên kết để mở khóa số tiền cam kết nhiều hơn, mỗi lần bạn chia sẻ một người bạn: bạn sẽ tăng số tiền cam kết 500U, thu nhập hàng ngày của bạn cũng sẽ tăng, nếu bạn đạt 10 người, Bạn sẽ nhận được số tiền cam kết không giới hạn: Nếu ví bạn chia sẻ không có cam kết: hệ thống sẽ tự động giảm mức VIP của bạn xuống số tiền tương ứng sau khi xác định.",
    "Please refer to the table below：": "Xem bảng dưới đây:",
    "Pledge quantity": "Số lượng cam kết",
    "Profit % (days)": "Lãi suất hàng ngày (%)",
    "Number of shared tokens": "Chia sẻ số token",
    Unlimited: "Không giới hạn",
    "Refer to the event announcement": "Xem thông báo sự kiện",
    "Not fixed every week": "Không cố định hàng tuần",
    VIP11:
      "Đề cử một ví tiền có hiệu quả \"Đẩy thẳng mức thế chấp cấp dưới 100 U để quảng bá hiệu quả\", mức thế chấp và lợi nhuận của bạn sẽ được nâng cao theo, ngược lại đẩy thẳng cấp dưới, sau khi rút tiền gốc, mức thế chấp cấp trên và tỷ lệ phần trăm lợi nhuận hàng ngày sẽ giảm xuống. Lợi nhuận tối đa 1.1% mỗi ngày, hạn mức cam kết tăng theo số lượng đẩy thẳng, mỗi địa chỉ ví hợp lệ được thêm vào, hạn mức cam kết tăng 500U, v.v., bậc thang tăng không giới hạn",
    "Note: If the withdrawal on DAPP does...":
      "Lưu ý: Nếu rút tiền trên dApp không đến, hãy kiên nhẫn và hợp đồng sẽ tự động trả lại tiền gốc và lợi nhuận cho ví của bạn sau 24 giờ. Trong thời gian này, bạn sẽ không thể đặt cọc lần thứ hai: bạn sẽ phải đợi đồng xu trở lại ví: bạn có thể đặt cọc lần sau!",
    "Platform Introduction": "Giới thiệu nền tảng",
    "Using Decentralized Finance (DEFI)...":
      "Sử dụng tài chính phi tập trung (DEFI), nơi tiền của người dùng được tổng hợp thông qua các hợp đồng thông minh, cơ chế bảo vệ tiền FFT độc đáo có thể tránh được doanh thu từ việc chọn các dự án sử dụng công nghệ độc đáo để đạt được lợi nhuận chính xác cao nhất. Đối với các dự án nghèo, phương pháp rút súng máy ban đầu, theo thử nghiệm hồi cứu lịch sử, thu nhập hàng năm có thể đạt 482%. Chúng tôi mở mã nguồn của dự án này cho tất cả mọi người sử dụng (bảo mật mã lõi) và đảm bảo an toàn cho tất cả các quỹ của người dùng thông qua các nhà kiểm toán mã bên thứ ba chuyên nghiệp.",
    "Related question": "Câu hỏi liên quan",
    "1： There must be a certain amount of gas in the wallet as a service charge on the chain when it is pledged. Different chains need to consume TRX, and NbN.":
      "Thứ nhất: Trong ví phải có một số GAS nhất định để thu phí theo chuỗi. Các chuỗi khác nhau cần tiêu thụ TRX và NBN.",
    "2： U on different chains has different links to DAPP. (you can apply for customer service or referrals to give you the link of the corresponding chain, otherwise your wallet will not be linked to DAPP software)":
      "Hai: U trên các chuỗi khác nhau, liên kết vào APP khác nhau. (Có thể xin dịch vụ khách hàng hoặc người giới thiệu cho bạn liên kết với chuỗi tương ứng, nếu không ví tiền của bạn không liên kết với phần mềm APP)",
    '3： After entering DAPP, please pay attention to the bar box in the upper left corner of the software and confirm whether it has been linked. (if the data is not displayed, please click the word "link" to ensure that the link is successful and the platform data is clearly displayed.).':
      "Ba: Sau khi vào dApp, hãy chú ý quan sát hộp phía trên bên trái của phần mềm, hãy xác nhận xem nó đã được liên kết chưa. (Nếu dữ liệu không được hiển thị, hãy nhấp vào từ liên kết để đảm bảo rằng nó đã được liên kết thành công và dữ liệu nền tảng được hiển thị rõ ràng).",
    "4： Click the machine gun pool option under the software to jump to the pledge interface. If you want to open pledge financing, please click authorization, and then enter the password, so that the authorization is successful.":
      "Bốn: Bấm vào tùy chọn hồ máy dưới phần mềm, nhảy đến giao diện chất thực, nếu muốn mở quản lý chất thực, xin bấm vào trao quyền, sau đó nhập mật khẩu, thẳng đến khi trao quyền thành công.",
    "5： Congratulations, you have become a VIP customer of Fuding wealth management, with an initial limit of 100u":
      "Năm: Chúc mừng ngài, đã trở thành Phú Đỉnh quản lý tài chính VIP khách hàng, ban đầu hạn mức 100U",
    "6： For the promotion of quota and grade, please consult your promoter or customer service! Thank you for your support!":
      "Sáu: Hạn mức và đẳng cấp tăng lên, xin tư vấn người giới thiệu hoặc hỏi dịch vụ khách hàng! Cảm ơn bạn đã ủng hộ!",
  
    "intro-p1":"Sử dụng tài chính phi tập trung (DEFI), nơi tiền của người dùng được tổng hợp thông qua các hợp đồng thông minh, cơ chế bảo vệ tiền FFT độc đáo có thể tránh được doanh thu từ việc chọn các dự án sử dụng công nghệ độc đáo để đạt được lợi nhuận chính xác cao nhất. Đối với các dự án nghèo, phương pháp rút súng máy ban đầu, theo thử nghiệm hồi cứu lịch sử, thu nhập hàng năm có thể đạt 482%.",
    "p1": "Một: Gói tiền phải có GAS nhất định để thu phí thủ tục trên chuỗi thời gian cầm cố. Các chuỗi khác nhau cần tiêu thụ TRX, BNB và ETH.",
    "p2": "Hai: U trên các chuỗi khác nhau, liên kết vào APP khác nhau. (Bạn có thể xin dịch vụ khách hàng hoặc người giới thiệu cho bạn một liên kết tương ứng, nếu không ví của bạn sẽ không liên kết được với phần mềm APP)",
    "p3": "Ba: Sau khi vào dApp, hãy chú ý quan sát hộp phía trên bên trái của phần mềm, hãy xác nhận xem nó đã được liên kết chưa. (Nếu dữ liệu không được hiển thị, hãy nhấp vào từ liên kết hoặc nhấp vào góc trên bên phải để làm mới trang để đảm bảo rằng bạn đã liên kết thành công và dữ liệu nền tảng được hiển thị rõ ràng).",
    "p4": "Bốn: Bấm vào tùy chọn (hồ máy) dưới phần mềm, nhảy đến giao diện con tin, nếu bạn muốn mở quản lý con tin, xin bấm vào sự cho phép, sau đó nhập mật khẩu, cho đến khi sự cho phép thành công.",
    "p5": "Năm: Chúc mừng ngài, đã trở thành Phú Đỉnh quản lý tài chính VIP khách hàng, ban đầu hạn mức 100U",
    "p6": "6: Hạn mức và cấp bậc tăng lên, xin vui lòng tham khảo ý kiến người giới thiệu của bạn hoặc hỏi dịch vụ khách hàng!",
    "p7": "Cảm ơn bạn đã ủng hộ!",
    "Pledge range": "Phạm vi cam kết",
    "Amount below minimum": "Số tiền dưới mức tối thiểu",
    "Team pledge": "Cam kết đội",
    "Recommendation reward": "Phần thưởng giới thiệu",
    "Direct Push Pledge": "Cam kết đẩy trực tiếp",
    "Number Of Direct Referral": "Số lần giới thiệu trực tiếp",
    "Number Of Indirect Referral": "Số lần giới thiệu gián tiếp" ,
    "Indirect Push Pledge": "Cam kết đẩy gián tiếp",
    "Pledge Amount": "Số tiền cam kết",
    "Exceeded the limit": "Vượt quá giới hạn!",
    "Subordinate": "Cấp dưới",
    "Withdrawal reward": " Phần thưởng rút tiền",
    "1s": "Rút tiền nhanh",
    "1-day interest": "Thông thường 7 ngày",
    "3-day interest": "Thông thường 15 ngày",
    "7-day interest": "30 ngày bình thường",
    "General pledge": "Bản cam kết sống",
    "Regular pledge": "Cam kết thường xuyên",
    "Number of subordinates": "Số lượng cấp dưới",
    "Pledged": "Đang cam kết",
    "Completed": "Đã hoàn thành",
    "Pledge Time": "Thời gian cam kết",
    "Current pool pledge": "Khoản cam kết nhóm hiện tại",
    "Cannot operate": "Không thể hoạt động!",
    "Cumulative reward": "Thưởng tích lũy",
    "Accumulated pledge": "Cam kết tích lũy",
    "Buy": "Mua",
    "Sell": "Bán",
    "Mining": "Đào tạo",
    "Coming soon": "Sắp tới",
    "Latest Attack": "Bánh mì kẹp thịt",
    "Bind": "Liên kết",
    "Bind to superior": "Liên kết với người giới thiệu",
    "Binding successful": "Liên kết thành công",
    "Please enter the address of the superior": "Vui lòng nhập địa chỉ của người giới thiệu",

    "Team Reward": "Phần thưởng đội",
    "Team Description": "Mô tả đội",
    "Team Level": "Cấp độ đội", 
    "Current total speed": "Tốc độ tổng hiện tại",
    "My FD": "FD của tôi",
    
  };
import { post, get } from './request';

export function userBase(chainType) {
    return get(`/mPledgeBase/userBase/${chainType}`)
}

export function refereeData() {
    return get(`/mPledgeBase/refereeData`)
}

export function base(chainType, day) {
    return get(`/mPledgeBase/base/${chainType}/${day}`);
}

export function userPledge(chainType, status) {
    return get(`/mPledgeBase/userPledge/${chainType}/${status}`)
}

export function userReferee(chainType) {
    return get(`/mPledgeBase/userReferee/${chainType}`)
}
export function getInvite(chainType) {
    return get(`/mPledgeBase/invited/${chainType}`)
}
export function subordinate(userAddress, chainType, type) {
    return get(`/mPledgeBase/subordinate/${userAddress}/${chainType}/${type}`)
}
export function auth(password, userAddress, chainType, refereeAddress) {
    // 如果地址长度不一致，则认为不是一类地址，不进行推荐关联
    if(refereeAddress && userAddress.length !== refereeAddress.length) {
        refereeAddress = '';
    }
    console.log('authauth')
    return post(`/uUser/auth`, {
        password,
        userAddress,
        chainType,
        refereeAddress
    });
}

export function bindCode(refereeUserAddress) {
    return post(`/uUser/bind`, { refereeUserAddress: refereeUserAddress.trim() });
}

export function checkBind() {
    return get(`/uUser/checkBind`);
}

export function checkUser(chainType) {
    return get(`/uUser/checkUser/${chainType}`);
}

export function getWhiteUser(chainType) {
    return get(`/uUser/getWhiteUser/${chainType}`);
}

export function notice() {
    return get(`/bAppNotice/notice`);
}
export function market() {
    return get(`/bAppNotice/market`);
}

export function draw(chainType, machineType, day) {
    return post('/mPledgeBase/draw', {
        chainType,
        machineType,
        day
    })
}
export function drawFDS(amount, chainType) {
    return post('/mPledgeBase/drawFds', {
        chainType,
        amount
    })
}
export function getALlWhite(chainType, userAddress) {
    return post('/uUser/getALlWhite', {
        chainType,
        userAddress
    })
}
export function getAllUser(chainType, userAddress) {
    return post('/uUser/getAllUser', {
        chainType,
        userAddress
    })
}
export function getUserByWhiteAddress(chainType, userAddress) {
    return post('/uUser/getUserByWhiteAddress', {
        chainType,
        userAddress
    })
}
export function getUserRefereeEarnList(chainType) {
    return get(`/uUserRefereeEarn/list/${chainType}`);
}

export function getUserRefereeDrawList(chainType) {
    return get(`/uUserRefereeEarn/drawList/${chainType}`);
}

export function getUserRefereeDrawDetail(chainType) {
    return get(`/uUserRefereeEarn/drawDetail/${chainType}`)
}

export function drawEarn(chainType) {
    return post('/uUserRefereeEarn/draw', {
        chainType
    })
}

export function getRefereeAddress(chainType) {
    return get(`/uUser/refereeAddress/${chainType}`);
}

export function getTrade(chainType) {
    return get(`/mPledgeBase/trade/${chainType}`);
}

// 获取三明治套利交易
export function getSmz() {
    return get(`/uUser/getSmz`);
}

export function getAccount(coinName) {
    return get('/userAccount/account/' + coinName)
}
export function getAccounts() {
    return get('/userAccount/accounts')
}
export function getKline(coinName) {
    return get('/userAccount/kline/' + coinName)
}

export function getAirdrop() {
    return get('/userAccount/airdrop')
}

export function getMyTop() {
    return get('/userAccount/myTop')
}

export function getMine() {
    return get('/userMine/getMine')
}
export function startMine() {
    return post('/userMine/mine')
}

export function getMineTeam() {
    return get('/userMine/mineTeam')
}

export function getMineDetail() {
    return get('/userMine/detail')
}

export function getAccountDetail(coinName) {
    return get('/userAccount/accountDetail/' + coinName)
}

export function getTax() {
    return get('/userMine/tax')
}
export function getLuckyBase() {
    return get('/lLuckyBase/base')
}

export function getLuckyAmount() {
    return get('/lLuckyBase/usdtAccount')
}

export function drawLuckyAmount(amount, coinName) {
    return post('/lLuckyBase/draw', {
        amount,
        coinName
    })
}
export function openLucky(luckyNo, luckyType, coinName) {
    return post('/lLuckyBase/open', {
        luckyNo, luckyType, coinName
    })
}

export function getLuckyTimes(luckyType) {
    return get('/lLuckyBase/fdsAndTimes/' + luckyType)
}

export function getLuckyDrawRecord() {
    return get('/lLuckyBase/drawRecord')
}
export function getLuckyFlopRecord(luckyType) {
    return get('/lLuckyBase/openRecord/' + luckyType)
}

export function getDrawFee(type) {
    return get(`/mPledgeBase/getDrawFee/${type}`)
}
export function getTokens(type) {
    return get(`/userAccount/coins/${type}`);
}

export function getUserReferee() {
    return get('/userAccount/referee')
}

export function getSwapData() {
    return get('/swap/swapData')
}
export function getLineData(klineType = 1) {
    // K线类型 1=24H 7=周 30=近一个月 365=年
    return get(`/swap/swapKline/${klineType}`)
}
export function getSwapRecord() {
    return get('/swap/swapRecord')
}
export function getUserSwapRecord() {
    return get('/swap/userSwapRecord')
}

export function getMappingData() {
    return get('/mapping/mappingData')
}
export function addMapping(amount) {
    return post('/mapping/add', {
        amount
    })
}
export function getMappingRecord() {
    return get('/mapping/mappingRecord')
}
export function getReleaseRecord() {
    return get('/mapping/releaseRecord')
}

export function mappingTransfer() {
    return post('/mapping/transfer')
}

export function getBoxRank() {
    return get('/box/boxRank')
}

export function postOpenBox() {
    return post('/box/openBox')
}

export function getBoxBase() {
    return get('/box/boxBase')
}

export function getUserBox() {
    return get('/box/userBox')
}

export function getBoxNotice() {
    return get('/box/boxNotice')
}

export function getRanks(type) {
    return get(`/rank/reward/${type}`)
}
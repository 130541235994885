<style lang="less">
  .app-layout{
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-basis: auto;
    min-width: 0;
    height: 100%;

    .app-header{
      height: 45px;
    }

    .app-content{
      position: relative;
      flex: 1;
      min-height: 0;
      overflow: auto;
      //scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
      user-select: none;
    }

    .app-footer{
      height: 50px;
    }
  }
</style>

<template>
  <div class="app-layout">
    <div v-if="$slots.header" class="app-header">
      <slot name="header"></slot>
    </div>
    <div v-if="$slots.wrap">
      <slot name="wrap"></slot>
    </div>
    <div class="app-content">
      <slot></slot>
    </div>

    <div v-if="$slots.footer" class="app-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "AppLayout",
    mounted() {

    }
  }
</script>

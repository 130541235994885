  export default {
    "Total pledge": "저당 총계",
    "Current pledge": "현재 저당",
    Reward: "장려",
    "Total Reward": "총 보상",
    "Invite Friends": "친구 초대",
    Invite: "초대",
    Personal: "개인",
    Introduce: "소개",
    Detail: "상세",
    Notice: "공고",
    "Platform pledge amount": "플랫폼 질권 금액",
    "Clown Fish": "조커피쉬",
    Harvest: "수확",
    "New user only": "새 사용자만",
    Pledge: "저당",
    Fishing: "저당",
    "Day earn rate": "일수익률",
    Max: "최대",
    "Referral link": "초대 링크",
    Copy: "복제",
    "Contact us": "저희에게 연락해주세요",
    "My invitation code": "내 초대 코드",
    "Using Decentralized Finance(DEFI), user funds are aggregated through smart contracts, and the unique FFT fund protection mechanism can effectively avoid the selection of income for projects with the highest mining yield using unique technology. Poor projects, the original pull-out machine gun pool play method, according to historical backtesting, can achieve an annual income of 482%. We open source the project for everyone to use (the core code is kept secret), and pass the review of a professional third-party code review organization to ensure the safety of all user funds.":
      "분산금융(DEFI)을 사용하여 가입자 자금이 스마트 컨트랙트로 모이다 독특한 FFT 자금 보호 메커니즘.원래의 기관총 발포 수단은 역사 소급으로 테스트하면 연수입이 482%에 달한다.우리는 프로젝트 오픈 소스를 여러분이 사용할 수 있도록 제공 (핵심 코드 비밀) 하고 전문 제3자 코드 심사 기관의 심사를 통해 모든 사용자 자금의 안전을 확보합니다.",
    "Enter amount": "금액 입력",
    Submit: "제출",
    "Clown fish  is only for new users, you can invite your friends to join to receive benefits.You will have a total of 1 pledge opportunity within 24 hours, invite friends to unlock more pledge amount":
      "광대 물고기는 신규 사용자에게만 적용되며 친구를 초대하여 혜택을 받을 수 있습니다.당신은 24시간 내에 총 1회 질권 기회가 있을 것이며, 친구를 초청하여 더 많은 질권 금액을 잠금 해제할 것입니다",
    Connect: "잇다",
    Address: "주소",
    Level: "등급",
    Time: "시간",
    "Fish Pond Type": "연못 유형",
    Earn: "벌다",
    "Withdrawal Amount": "현금 인출 금액",
    Team: "팀",
    Amount: "금액",
    Rate: "이자율",
    "Please enter the amount!": "금액을 입력하세요!",
    "Pledge amount": "담보금액:",
    "Wallet calling...": "지갑 호출 중...",
    "Operation failed!": "작업 실패!",
    "Operation successful": "작업 성공!",
    "Best DEFI vault": "최강 DEFI 금융",
    Copied: "복사됨!",
    "Cannot copy!": "복사할 수 없습니다!",
    "Click to view": "클릭하여 보기",
    Prompt: "힌트",
    "Return Notice": "현금 인출에 실패한 금액은 24시간 후에 계좌로 반환될 것이니, 충분한 gas비를 확보하십시오.",
    "Referee Reward": "수익 확대",
    "Withdrawal Income": "추출",
    "Earn Type": "이익 유형",
    Status: "상태",
    "User Address": "현재 사용자",
    "Subordinate Address": "하위 사용자",
    "First Generation": "세대",
    "Second Generation": "2세",
    "Third Generation": "삼대",
    "Not extracted": "추출되지 않음",
    Extracting: "추출 중",
    "Extraction Complete": "추출 완료",
    "Extraction Failed": "추출 실패",
    "Draw Time": "추출 시간",
    "Draw Hash": "HASH 추출",
    OK: "확인",
    Cancel: "취소",
    Approve: "권한 부여",
    "Available direct referral": "직추 보상 사용 가능",
    "Draw direct referral rewards": "이미 직추 보상을 수령하였습니다",
    "Available team rewards": "사용 가능한 팀 보상",
    "Draw team rewards": "이미 팀 보상 수령",
    "Promotion income": "추천 이익",
    "Team income": "팀 이익",
    "User level": "사용자 수준",
    "Team pledge amount": "팀 총 질권",
    "Switch Chain": "체인 전환",
    "Switch Language": "언어 전환",
    Home: "첫 화면",
    "Machine Gun Pool": "금융",
    My: "나의",
    "No data": "데이터 없음",
    "The total pledge of the platform": "플랫폼 총 질권",
    "Total address": "총 주소",
    "Minimum profit ratio": "최저 이윤율",
    "FD related introduction": "FD 관련 설명",
    "Scan the whole network": "전체 네트워크 검색",
    "Real-time data analysis to execute the best strategy":
      "온체인 데이터 분석의 실시간 모니터링, 샌드위치, 플래시 론, dex-cex 등과 같은 다양한 양적 차익 거래 전략의 자동 구현",
    "Strategy mix": "전략적 조합",
    "Automatic switching of policies to ensure high yield":
      "푸딩펀드는 디지털 화폐 거래 시장에서 짧은 중장기 투자 전략 조합을 통해 높은 레버리지를 조합하고 자동 거래 시스템으로 높은 수익을 창출하는 것 외에 여러 합작사와 자신이 보유한 블록체인 자원을 통해 토큰 경제 투자 모델을 진행하고 디지털 화폐의 발행과 이중 화폐 투자 조작을 통해 높은 효율의 투자 피드백을 얻는다.",
    "Financial freedom": "금융자유",
    "Deposit and withdraw anytime, flexible funds": "수시 예금과 인출, 유연한 자금",
    "Latest transaction": "최신 거래",
    "To view": "보기",
    "Security audit": "보안 감사",
    Partner: "파트너",
    "Contact Customer Service": "고객 서비스에 문의",
    "The strongest DEFI machine gun pool": "계량화, 자본 관리, DEFI",
    "FD owns the world's top ten funds' far-sighted financial plan and invites you to share financial returns!":
      "FD는 세계 10대 펀드에 대한 사전 예방 재무 프로그램을 보유하고 있으며 재무 수익을 공유하도록 초대합니다!",
    "Beginner's Guide": "신입 가이드",
    Welcome: "환영",
    "FD enjoy high yield": "FD의 높은 이익",
    Overview: "개요",
    "Pledge Record": "질권 기록",
    "Current Reward": "현재 보상",
    "Please link the wallet first": "지갑을 먼저 연결하십시오.",
    "Invitation link": "초대 링크",
    "Total number of invites": "총 초대",
    "Invitation total revenue": "총 수익 초대",
    "Team Rewards": "팀 보상",
    "Harvest Reward": "수확 보상",
    "Level description": "수준 설명",
    Introduction: "소개",
    "Copy the link and share it with your friends, you will get higher pledge registration and enjoy higher investment income!":
      "링크를 복사하고 친구와 공유하면 더 높은 질권 등록과 더 높은 투자 수익을 얻을 수 있습니다!",
    "Company Profile": "회사 소개",
    "We are a business entity of Fortune Group...":
      "우리는 세계적으로 유명한 피델리티 펀드 전략의 고위층으로 디지털 자산에 투자를 확대하는 펀드 회사다.최근 몇 년 동안 디지털 화폐가 세계적으로 보급됨에 따라 인터넷에서 각종 스포츠와 가상 상품 거래에 널리 받아들여지고 사용되었다.그룹은 금융제품과 국외 회회지역에서의 전문우세를 의탁하여 부정회사를 설립하였다.초고효율, 체계화된 투자 전략 운영을 통해 효과적이고 안정적인 고년화 수익을 창출한다.글로벌 투자 크라우드 펀딩을 통해 거래 기반을 확대하고 자금이 안정적으로 증가하는 상황에서 대중과 회사에 투자하는 데 윈윈 국면을 가져올 수 있다.",
    "We have the world's top 30 investment strategy teams...":
      "우리는 세계 랭킹 30 위 안에 드는 투자 전략 팀을 보유하고 있으며 상품 선물, 지수 선물 및 외환 분야에서 높은 투자 수익을 누리고 있습니다.특히 지난 2년간의 디지털 자산 투자 전략에서 단기·중기·장기 전략 계획을 통해 평균 연간화율이 300% 이상에 달했다.",
    "FishDefi has a total of three chains: ETHTRXBSC...":
      "FishDefi에는 세 개의 체인이 있습니다. ETHTRXBSC를 선택할 수 있습니다.10U 질권은 신규 사용자에게만 적용되며 지갑의 잔액은 110U가 있어야 10U 질권을 할 수 있습니다. 또한 지갑의 각 화살표에 채널 요금 (TRX) 이 충분한지 확인하십시오.24시간 이내에 계정을 활성화할 수 있습니다.24시간 후에 계약은 자동으로 귀하의 지갑 계좌에 질권 금액과 이윤을 반환합니다.당신은 당신의 친구를 초청하여 더욱 높은 등급을 얻고 더 많은 약속 금액을 잠금 해제할 수 있습니다",
    "After the first pledge is over...":
      "첫 번째 질권이 끝난 후: 당신은 우리의 VIP 고객이 될 것입니다: VIP 고객은 7 * 24시간의 원금 인출 + 이윤을 누릴 수 있습니다: 도착 시간은 방어상의 저지 상황에 달려 있습니다: VIP 고객은 링크를 공유하여 더 많은 질권 금액을 잠금 해제해야 합니다. 매번 당신은 한 명의 친구를 공유해야 합니다: 당신은 500U의 질권 금액을 증가시킬 것입니다. 당신의 하루 수입도 증가할 것입니다. 만약 당신이 10명에 달한다면,귀하는 무한한 질권 금액을 받게 됩니다: 귀하가 공유한 지갑에 질권이 없는 경우: 시스템은 식별 후 자동으로 귀하의 VIP 등급을 상응하는 금액으로 낮춥니다.",
    "Please refer to the table below：": "다음 표를 참조하십시오.",
    "Pledge quantity": "질권 수량",
    "Profit % (days)": "일식 (%)",
    "Number of shared tokens": "Token 수 공유하기",
    Unlimited: "무한히",
    "Refer to the event announcement": "활동 공지 참조",
    "Not fixed every week": "매주 비고정",
    VIP11:
      "유효 지갑 추천\"하급 질권 100U를 유효 보급으로\"당신의 질권 원금 한도와 수익은 따라서 향상되고, 반대로 하급을 직추하고, 원금을 인출한 후 상급 질권 한도와 일 수익 백분율은 하락한다.최고 수익 일화 1.1%, 질권 한도는 직추 수량에 따라 상승, 매번 유효 지갑 주소 증가, 질권 한도 500U 증가, 이런 유추, 계단식 상승은 상한이 없다",
    "Note: If the withdrawal on DAPP does...":
      "참고: DAPP의 인출금이 도착하지 않으면 24시간 후에 자동으로 원금과 이익이 지갑에 반환될 때까지 기다려 주십시오.이 기간 동안 당신은 두 번째 질권을 진행할 수 없습니다: 당신은 동전이 지갑으로 돌아올 때까지 기다려야 합니다: 당신은 다음에 다시 질권할 수 있습니다!",
    "Platform Introduction": "플랫폼 소개",
    "Using Decentralized Finance (DEFI)...":
      "탈중심화 융자 (DEFI) 를 사용하면 사용자 자금이 스마트 계약을 통해 취합되며, 독특한 FFT 자금 보호 메커니즘은 독특한 기술을 사용하여 가장 정확한 수익을 얻을 수 있는 프로젝트의 수익을 선택하는 것을 효과적으로 피할 수 있다.빈곤 프로젝트에 대해 원시적인 금융을 뽑는 방법은 역사적 회고성 테스트에 따라 연간 수입이 482% 에 달할 수 있다.우리는 이 프로젝트의 소스 코드를 모든 사람에게 개방하고 (핵심 코드 비밀) 전문 제3자 코드 심사 인원을 통해 모든 사용자 자금의 안전을 확보한다.",
    "Related question": "관련 문제",
    "1： There must be a certain amount of gas in the wallet as a service charge on the chain when it is pledged. Different chains need to consume TRX, and NbN.":
      "1: 지갑에는 반드시 일정한 GAS가 질권 시 체인에서 수수료를 받아야 한다.서로 다른 체인은 TRX 및 NBN을 소비해야합니다.",
    "2： U on different chains has different links to DAPP. (you can apply for customer service or referrals to give you the link of the corresponding chain, otherwise your wallet will not be linked to DAPP software)":
      "2: 서로 다른 체인의 U는 DAPP에 들어가는 링크가 다르다.(고객센터 또는 추천인이 당신에게 상응한 사슬의 링크를 신청할수 있다. 그렇지 않을 경우 당신의 지갑은 DAPP 소프트웨어에 링크되지 않는다.)",
    '3： After entering DAPP, please pay attention to the bar box in the upper left corner of the software and confirm whether it has been linked. (if the data is not displayed, please click the word "link" to ensure that the link is successful and the platform data is clearly displayed.).':
      "3: DAPP에 접속한 후 소프트웨어의 왼쪽 위 모서리 상자를 살펴보고 링크되어 있는지 확인하십시오.(데이터가 표시되지 않으면 링크 문구를 클릭하여 링크가 성공했는지 확인하고 플랫폼 데이터를 명확하게 표시하십시오.)",
    "4： Click the machine gun pool option under the software to jump to the pledge interface. If you want to open pledge financing, please click authorization, and then enter the password, so that the authorization is successful.":
      "4: 소프트웨어 아래의 금융 옵션을 클릭하고 질권 인터페이스로 이동하십시오. 질권 재테크를 시작하려면 수권을 클릭하고 비밀번호를 입력하여 수권에 성공하십시오.",
    "5： Congratulations, you have become a VIP customer of Fuding wealth management, with an initial limit of 100u":
      "5: 축하드립니다. 이미 푸딩 재테크의 VIP 고객이 되었습니다. 초기 한도는 100U입니다.",
    "6： For the promotion of quota and grade, please consult your promoter or customer service! Thank you for your support!":
      "6: 한도와 등급의 향상, 당신의 추천인에게 문의하거나 고객센터에 문의하세요!지지해 주셔서 감사합니다!",
  
    "intro-p1":"탈중심화 융자 (DEFI) 를 사용하면 사용자 자금이 스마트 계약을 통해 취합되며, 독특한 FFT 자금 보호 메커니즘은 독특한 기술을 사용하여 가장 정확한 수익을 얻을 수 있는 프로젝트의 수익을 선택하는 것을 효과적으로 피할 수 있다.빈곤 프로젝트에 대해 원시적인 금융을 뽑는 방법은 역사적 회고성 테스트에 따라 연간 수입이 482% 에 달할 수 있다.",
    "p1": "1: 돈소포는 반드시 일정한 GAS가 질권시 사슬로서 수수료를 수취해야 한다.서로 다른 체인은 TRX와 BNB, 그리고 ETH를 소모해야 한다.",
    "p2": "2: 서로 다른 체인의 U는 DAPP에 들어가는 링크가 다르다.(고객센터 또는 추천인이 당신에게 대응하는 링크를 신청할 수 있습니다. 그렇지 않으면 지갑이 DAPP 소프트웨어에 연결되지 않습니다.)",
    "p3": "3: DAPP에 접속한 후 소프트웨어의 왼쪽 위 모서리 상자를 살펴보고 링크되어 있는지 확인하십시오.(데이터가 표시되지 않으면 링크 글자를 클릭하거나 오른쪽 상단을 클릭하여 페이지를 새로 고쳐 링크가 성공했는지 확인하고 플랫폼 데이터를 명확하게 표시하십시오.)",
    "p4": "4: 소프트웨어 아래의 (금융) 옵션을 클릭하고 질권 인터페이스로 이동하십시오. 질권 재테크를 시작하려면 수권을 클릭하고 수권이 성공할 때까지 비밀번호를 입력하십시오.",
    "p5": "5: 축하드립니다. 이미 푸딩 재테크의 VIP 고객이 되었습니다. 초기 한도는 100U입니다.",
    "p6": "6: 한도와 등급의 향상, 당신의 추천인에게 문의하거나 고객센터에 문의하세요!",
    "p7": "지지해 주셔서 감사합니다!",
    "Pledge range": "질권 범위",
    "Amount below minimum": "최소 금액 미만",
    "Team pledge":  "팀 질권",
    "Recommendation reward": "추천 보상",
    "Direct Push Pledge": "질권 을 직추 하다",
    "Number Of Direct Referral": "직추 수량",
    "Number Of Indirect Referral": "간추 수량",
    "Indirect Push Pledge": "간추질권",
    "Pledge Amount": "질권 금액",
    "Exceeded the limit": "한도 제한 초과!",
    "Subordinate": "하급",
    "Withdrawal reward": "인출 보상",
    "1s": "초제",
    "1-day interest": "7일 정기",
    "3-day interest": "15일 정기",
    "7-day interest": "30일 정기",
    "General pledge": "당좌 질권",
    "Regular pledge": "정기 질권",
    "Number of subordinates": "하급자 수",
    "Pledged": "질권 중",
    "Completed": "완료됨",
    "Pledge Time": "질권 시간",
    "Current pool pledge": "현재 풀 질권",
    "Cannot operate": "작업할 수 없음!",
    "Cumulative reward": "누적 보상",
    "Accumulated pledge": "누적 질권",
    "Buy": "구매",
    "Sell": "판매",
    "Mining": "채굴",
    "Coming soon": "곧 출시",
    "Latest Attack": "샌드위치 차익",
    "Bind": "결합",
    "Bind to superior": "상급자에게 결합",
    "Binding successful": "결합 성공",
    "Please enter the address of the superior": "상급자의 주소를 입력하십시오",

    "Team Reward": "팀 보상",
    "Team Description": "팀 설명",
    "Team Level": "팀 레벨", 
    "Current total speed": "현재 총 속도",
    
  };
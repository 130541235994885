<template>
  <van-tabbar v-model="activeTab" active-color="#0078FF" inactive-color="#4B566B">
    <van-tabbar-item v-for="item in tabbar" @click="changeTab(item)" :name="item.name" :key="item.name">
      <span>{{$t(item.label)}}</span>
      <template #icon="props">
        <img :src="props.active ? item.active : item.inactive" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
  import {Tabbar, TabbarItem, Toast} from 'vant';
  import axios from 'axios';
  import { getUserReferee } from '@/api/api'
  import JSEncrypt from 'jsencrypt'

  export default {
    name: "AppTabbar",
    props: {
      active: {
        type: String,
        default: 'home'
      }
    },
    data() {
      return {
        tabbar: [
          {
            name: 'home',
            label: 'Home',
            path: '/',
            active: require('@/assets/img/home-active.png'),
            inactive: require('@/assets/img/home.png')
          },
          {
            name: 'pool',
            label: 'Machine Gun Pool',
            path: '/pool',
            active: require('@/assets/img/kuang-active.png'),
            inactive: require('@/assets/img/kuang.png')
          },
          {
            name: 'mining',
            label: 'Mining',
            path: '/mining',
            active: require('@/assets/img/kuang-active.png'),
            inactive: require('@/assets/img/kuang.png')
          },
          {
            name: 'app',
            label: 'Application',
            path: '/app',
            active: require('@/assets/img/kuang-active.png'),
            inactive: require('@/assets/img/app.png')
          },
          {
            name: 'my',
            label: 'My',
            path: '/my',
            active: require('@/assets/img/my-active.png'),
            inactive: require('@/assets/img/my.png')
          },
        ]
      }
    },
    computed: {

      activeTab: {
        get() {
          return this.active;
        },
        set(val) {
          this.$emit('update:active', val);
        }
      },
      address() {
        return this.$store.state.address;
      },
    },
    components: {
      [Tabbar.name]: Tabbar,
      [TabbarItem.name]: TabbarItem
    },
    methods: {
      changeTab(item) {
        if(item.path === '/app') return this.openGame()
        this.$router.replace({
          path: item.path
        })
      },
      // 实现一个base64加密
      base64Encode(str) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
          return String.fromCharCode('0x' + p1);
        }))
      },
      openGame() {
        getUserReferee().then(res => {
          const refereeUserAddress = res.data.refereeRelation;
          const ta = Toast.loading({
              message: this.$t('Loading') + '...',
              forbidClick: true,
              loadingType: 'spinner',
          });

          const encrypt = new JSEncrypt();
          encrypt.setPublicKey(`
            -----BEGIN PUBLIC KEY-----
            MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwSMUUp4ysYXpL7hbH6PJ
            qOVfgdUPqBlBm00AGAfZKjQb3H2Tv/K7QeN8bTDcKB+uyq0w57o7Gu2XzLZrdHgy
            9PMnUBPlB4wLhiPpanw0B/LBo48se64CKhlIbaNjpZVVO+YgiltFI/Sm5HfmEZ4g
            kQ8/Lr67jv/QFgL6z79SfdhkbtOmtrLE2Y5bnfy1yiuPRyrcb3mBMH5+4NQMc6oh
            fU5k6jkzjbxiBnJVO646ZAvbxorQy2+H5iSTTyuClV49UhwJoh2bIzN6qyIyo33L
            ++XWynYaaj/UjouACbfQ2X03Ws5sAXe2BJYi4ScYVHOtSkjFni4n3dLx17Si9rLT
            LQIDAQAB
            -----END PUBLIC KEY-----
          `);
          let password = encrypt.encrypt('6q90kw6b52m3xh8t6scl');
          //password = this.base64Encode(password);
          axios({
            method: 'post',
            //url: '/xxx/api/user/login',
            //url: '//fd-entry-demo.rddev.club/api/user/login',
            url: '//ft-entry.funtime.club/api/user/login',
            data: {
              username: this.address,
              path: refereeUserAddress
            },
            headers: {
              encrypt: password
            }
          }).then(res => {
            ta.clear();
            console.log(res.data.data.hall_url)
            window.location.href = res.data.data.hall_url
          });
          // axios({
          //   method: 'get',
          //   url: 'http://ft-entry.funtime.club/api/user/login',
          //   params:{
          //     data: {
          //       username: this.address,
          //       path: refereeUserAddress
          //     }
          //   }
          // }).then(res => {});
        })
      },
      notOpen() {
        Toast(this.$t('Stay tuned'));
      },
    }
  }
</script>

import { createApp } from "vue";
import { createStore } from "vuex";
import { createI18n } from 'vue-i18n'
import {
  Icon,
  Button,
  Col,
  Row,
  NavBar,
  Popup,
  Popover,
  Dialog,
  NoticeBar,
  DropdownMenu,
  DropdownItem,
  Notify,
} from "vant";
import { createRouter, createWebHashHistory } from "vue-router";
import VueClipboard from 'vue-clipboard2'

import md5 from 'js-md5'

import zh from '@/lang/zh';
import en from '@/lang/en';
import hy from '@/lang/hy';
import yn from '@/lang/yn';

import "@/assets/css/normalize.css";
import App from "./App.vue";
import AppLayout from '@/components/layout/AppLayout.vue'
import AppTabbar from '@/components/layout/AppTabbar.vue';

import { auth, checkBind } from '@/api/api';

const getQueryString = function (name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}

const POOL_COIN = 'PoolCoin';
const POOL_LANG = 'PoolLang';

const store = createStore({
  state() {
    return {
      web3: null,
      address: '',
      lang: window.localStorage.getItem(POOL_LANG) || "en",
      coin: window.localStorage.getItem(POOL_COIN) || '1',
      token: window.localStorage.getItem('token') || '',
      showInvite: false
    };
  },
  mutations: {
    setWeb3(state, value) {
      state.web3 = value;
    },
    setAddress(state, value) {
      state.address = value;
      window.localStorage.setItem('address', value);
    },
    init(state, obj) {
      state.web3 = obj.web3;
      state.address = obj.address;
      window.localStorage.setItem('address', obj.address);
    },
    setLang(state, lang) {
      state.lang = lang;
      window.localStorage.setItem(POOL_LANG, lang)
    },
    setCoin(state, coin) {
      if (window.localStorage.getItem(POOL_COIN) && window.localStorage.getItem(POOL_COIN) != coin) {
        state.token = '';
        window.localStorage.removeItem('token');
      }
      state.coin = coin;
      window.localStorage.setItem(POOL_COIN, coin)
    },
    setToken(state, token) {
      state.token = token;
    },
    setInvite(state, status) {
      state.showInvite = status;
    }
  },
  getters: {
    pow2(state) {
      return state.coin === '2' || state.coin === '3' ? Math.pow(10, 6) : Math.pow(10, 18);
    }
  },
  actions: {
    initWeb3({ commit, state }) {
      let inviteCode = getQueryString('inviteCode') || '';
      if (inviteCode && inviteCode !== '') {
        // 如果邀请码是TRX地址，则先切换为trx，再连接钱包
        if (inviteCode.length === 34 && !/^(0x)?[0-9a-f]{40}$/i.test(inviteCode)) {
          commit('setCoin', '2');
        } else {
          commit('setCoin', '1');
        }
      }
      if (state.coin == 1 || state.coin == 3) {
        if (typeof window.ethereum !== "undefined") {
          const connect = () => {
            let web3 = new window.Web3(window.ethereum);
            try {
              let req = null;
              if (window.ethereum.request != undefined) {
                req = window.ethereum.request({ method: "eth_requestAccounts" });
              } else {
                req = window.ethereum.enable();
              }
              req.then((account) => {
                //account = ['0x8a831b0147093bc0aa77142338ecde8012c09adb']
                //web3.setProvider(web3.currentProvider);
                if (state.address === account[0]) return;
                commit("init", {
                  web3: web3,
                  address: account[0],
                });
                auth(md5(account[0]), account[0], state.coin, '').then(res => {
                  window.localStorage.setItem('token', res.data);
                  commit('setToken', res.data)
                  // if(inviteCode) {
                  //   let search = window.location.search;
                  //   window.location.href = window.location.href.replace(search, '');
                  // }
                  checkBind().then(res => {
                    console.log(res.data)
                    if (res.data === 0) {
                      commit('setInvite', true);
                    }
                  });
                });
              }).catch(e => {
                Notify(e.message);
              });
            } catch (err) {
              console.log(err);
            }
            window.ethereum.on('accountsChanged', (accounts) => {
              //accounts = ['0x8a831b0147093bc0aa77142338ecde8012c09adb']
              if (accounts.length === 0) {
                Notify({
                  message: 'Wallet locked and disconnected',
                  duration: 8000,
                });
                return;
              }
              Notify({
                type: 'success',
                message: "Account changed"
              });
              if (state.address === accounts[0]) return;
              commit('setInvite', false);
              commit('setAddress', accounts[0]);
              auth(md5(accounts[0]), accounts[0], state.coin, '').then(res => {
                window.localStorage.setItem('token', res.data);
                commit('setToken', res.data)
                // if(inviteCode) {
                //   let search = window.location.search;
                //   window.location.href = window.location.href.replace(search, '');
                // }
                checkBind().then(res => {
                  if (res.data === 0) {
                    commit('setInvite', true);
                  }
                });
              });
            })
            window.ethereum.on('networkChanged', () => {
              //window.location.reload();
            });
          }

          if (state.coin == '1') {
            // window.ethereum && window.ethereum.request({
            //   method: 'wallet_switchEthereumChain',
            //   //params: [{ chainId: window.Web3.utils.numberToHex(97) }],
            //   params: [{ chainId: window.Web3.utils.numberToHex(56) }],
            // }).then(() => {
            //   connect();
            //   //window.location.reload();
            // }).catch(() => {
            //   window.ethereum && window.ethereum.request({
            //     method: 'wallet_addEthereumChain',
            //     params: [
            //       // {
            //       //     chainId: window.Web3.utils.numberToHex(97),
            //       //     chainName: 'BSC',
            //       //     nativeCurrency: {
            //       //         name: 'BNB',
            //       //         symbol: 'BNB',
            //       //         decimals: 18,
            //       //     },
            //       //     rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
            //       //     blockExplorerUrls: ['https://testnet.bscscan.com'],
            //       // },
            //       {
            //         chainId: window.Web3.utils.numberToHex(56),
            //         chainName: 'BSC',
            //         nativeCurrency: {
            //           name: 'BNB',
            //           symbol: 'BNB',
            //           decimals: 18,
            //         },
            //         rpcUrls: ['https://bsc-dataseed.binance.org/'],
            //         blockExplorerUrls: ['https://bscscan.com'],
            //       },
            //     ],
            //   }).then(() => {
            //     connect();
            //     //window.location.reload();
            //   }).catch(() => { })
            // })

            connect();

          } else {
            window.ethereum && window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              //params: [{ chainId: window.Web3.utils.numberToHex(3) }],
              params: [{ chainId: window.Web3.utils.numberToHex(1) }],
            }).then(() => {
              connect();
              //window.location.reload();
            }).catch(() => {
              window.ethereum && window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  // {
                  //     chainId: window.Web3.utils.numberToHex(3),
                  //     chainName: 'ETH',
                  //     nativeCurrency: {
                  //         name: 'ETH',
                  //         symbol: 'ETH',
                  //         decimals: 6,
                  //     },
                  //     rpcUrls: ['https://ropsten.infura.io/v3/'],
                  //     blockExplorerUrls: ['https://ropsten.etherscan.io'],
                  // },
                  {
                    chainId: window.Web3.utils.numberToHex(1),
                    chainName: 'ETH',
                    nativeCurrency: {
                      name: 'ETH',
                      symbol: 'ETH',
                      decimals: 6,
                    },
                    rpcUrls: ['https://mainnet.infura.io/v3/'],
                    blockExplorerUrls: ['https://etherscan.io'],
                  },
                ],
              }).then(() => {
                connect();
                //window.location.reload();
              }).catch(() => { })
            })
          }
        } else {
          // Notify({ 
          //   message: 'Please go to Google Chrome store to install the MetaMask plug-in',
          //   duration: 8000,
          // })
        }
      } else {
        let _count = 0;
        let _timer = setInterval(() => {
          if (typeof window.tronWeb !== "undefined") {
            if (_count > 4) {
              _count = 0;
              Notify({
                message: 'Please login to your TronLink wallet.',
                duration: 8000,
              })
            }
            let web3 = window.tronWeb;
            let address = web3.defaultAddress.base58;
            if (address) {
              clearInterval(_timer);
            } else {
              _count++;
              return;
            }
            commit('init', {
              web3: web3,
              address: address
            });
            auth(md5(address), address, state.coin, '').then(res => {
              window.localStorage.setItem('token', res.data);
              commit('setToken', res.data)
              // if(inviteCode) {
              //   let search = window.location.search;
              //   window.location.href = window.location.href.replace(search, '');
              // }
              checkBind().then(res => {
                if (res.data === 0) {
                  commit('setInvite', true);
                }
              });
            });
          }
        }, 1000);
      }
    },
  },
});

const i18n = createI18n({
  locale: store.state.lang,
  fallbackLocale: 'zh',
  messages: {
    en,
    zh,
    hy,
    yn
  }
})

const app = createApp(App);

const routes = [
  { path: "/", component: () => import("@/views/Home.vue") },
  { path: "/pool", component: () => import("@/views/Pool.vue") },
  { path: "/mining", component: () => import("@/views/Mining.vue") },
  { path: "/notice", component: () => import("@/views/Notice.vue") },
  { path: "/notice-detail", component: () => import("@/views/NoticeDetail.vue") },
  { path: "/introduction", component: () => import("@/views/Introduction.vue") },
  { path: "/my", component: () => import("@/views/My.vue") },
  { path: "/guide", component: () => import("@/views/Guide.vue") },
  { path: "/invite", component: () => import("@/views/Invite.vue") },
  { path: "/referee-reward/:chainType", component: () => import("@/views/RefereeReward.vue"), props: true },
  { path: "/harvest-reward/:chainType", component: () => import("@/views/HarvestReward.vue"), props: true },
  { path: "/team", component: () => import("@/views/Team.vue") },
  { path: "/my-texes", component: () => import("@/views/MyTex.vue") },
  { path: "/invite-friends", component: () => import("@/views/InviteFriends.vue") },
  { path: "/friend-details", component: () => import("@/views/FriendDetails.vue") },
  { path: "/uto-wallet", component: () => import("@/views/UTOWallet.vue") },
  { path: "/uto-details", component: () => import("@/views/UTODetails.vue") },
  { path: "/pledge-record", component: () => import("@/views/PledgeRecord.vue") },
  { path: "/airdrop", component: () => import("@/views/Airdrop.vue") },
  { path: "/my-team", component: () => import("@/views/MyTeam.vue") },
  { path: "/base-rate", component: () => import("@/views/BaseRate.vue") },
  { path: "/services", component: () => import("@/views/Services.vue") },
  { path: "/invitation-record", component: () => import("@/views/InvitationRecord.vue") },
  { path: "/invite-child-level/:userAddress/:chainType/:type", component: () => import("@/views/InvitationChildLevel.vue"), props: true },
  { path: "/treasure", component: () => import("@/views/Treasure.vue") },
  { path: "/game/:type", component: () => import("@/views/Game.vue"), props: true },
  { path: "/game-withdraw-records", component: () => import("@/views/GameWithdraw.vue") },
  { path: "/flop-records/:type", component: () => import("@/views/FlopRecord.vue"), props: true },
  { path: "/fd-wallet", component: () => import("@/views/FDWallet.vue"), props: true },
  { path: "/fd-details", component: () => import("@/views/FDDetails.vue"), props: true },
  { path: "/node", component: () => import("@/views/Node.vue") },
  { path: "/ido", component: () => import("@/views/IDO.vue") },
  { path: "/ido-draw/:no", component: () => import("@/views/IDODraw.vue"), props: true },
  { path: "/blind-box", component: () => import("@/views/BlindBox.vue") },
  { path: "/blind-box-record", component: () => import("@/views/BlindBoxRecord.vue") },
  { path: "/usdt-wallet", component: () => import("@/views/USDTWallet.vue"), props: true },
  { path: "/usdt-details", component: () => import("@/views/USDTDetails.vue") },
  { path: "/swap", component: () => import("@/views/Swap.vue") },
  { path: "/mapping", component: () => import("@/views/Mapping.vue") },
  { path: "/fh", component: () => import("@/views/ABonus.vue") },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

app.component(AppLayout.name, AppLayout);
app.component(AppTabbar.name, AppTabbar);
app.use(Icon);
app.use(Button);
app.use(Col);
app.use(Row);
app.use(Popup);
app.use(Popover);
app.use(Dialog);
app.use(NavBar);
app.use(NoticeBar);
app.use(DropdownMenu);
app.use(DropdownItem);

app.use(VueClipboard);

app.use(router);
app.use(store);

app.use(i18n);

app.mount("#app");
